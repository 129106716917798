import React from "react"
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"

import { AxiosInterceptor } from "../config/Axios"
import ScrollToTop from "../utils/ScrollToTop"
import UnAuthenticated from "./UnAuthenticated"
import Authenticated from "./Authenticated"
import Error from "../pages/error/Error"
import Compliance from "../components/compliance"

const LandingPage = React.lazy(() => import("../pages/home/index"))
const OnboardingPage = React.lazy(() => import("../pages/auth/onboarding/index"))
const SignUpPage = React.lazy(() => import("../pages/auth/signUp/index"))
const LogInPage = React.lazy(() => import("../pages/auth/logIn/index"))
const ForgotPassword = React.lazy(() => import("../pages/auth/forgotPassword/index"))
const ResetPassword = React.lazy(() => import("../pages/auth/resetPassword"))
const Investments = React.lazy(() => import("../pages/dashboard/Investments"))
const Savings = React.lazy(() => import("../pages/dashboard/Savings"))
const Settings = React.lazy(() => import("../pages/dashboard/Settings"))
const SingleInvestment = React.lazy(() => import("../pages/dashboard/packages/SingleInvestment"))
const CreateInvestment = React.lazy(() => import("../pages/dashboard/packages/CreateInvestment"))
const InvestmentDetails = React.lazy(() => import("../pages/dashboard/packages/InvestmentDetails"))
const Liquidate = React.lazy(() => import("../pages/dashboard/packages/Liquidate"))
const FaqPage = React.lazy(() => import("../pages/generic/FAQ"))
const CompleteInvestment = React.lazy(() =>
  import("../pages/dashboard/packages/CompleteInvestment"),
)

const AppRoutes = () => {
  return (
    <Router>
      <ScrollToTop />
      <Compliance />
      <AxiosInterceptor />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route element={<UnAuthenticated />}>
          <Route path="/auth/login" element={<LogInPage />} />
          <Route path="/auth/signup" element={<SignUpPage />} />
          <Route path="/auth/reset-password" element={<ResetPassword />} />
          <Route path="/auth/select-account" element={<OnboardingPage />} />
          <Route path="/auth/forgot-password" element={<ForgotPassword />} />
        </Route>
        <Route element={<Authenticated />}>
          <Route path="/savings" element={<Savings />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/investments" element={<Investments />} />
          <Route path="/:type/:name" element={<SingleInvestment />} />
          <Route path="/summary/:id" element={<InvestmentDetails />} />
          <Route path="/:type/:name/create" element={<CreateInvestment />} />
          <Route path="/liquidate/:id" element={<Liquidate />} />
          <Route path="/transaction-invoice" element={<CompleteInvestment />} />
        </Route>
        <Route path="/faqs" element={<FaqPage />} />
        <Route path="/404" element={<Error />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </Router>
  )
}

export default AppRoutes
