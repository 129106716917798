import { useEffect, useState } from "react"
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} from "@mui/material"
import AppButton from "../common/AppButton"

const key = window.location.origin + "_privacy"

const PrivacyPolicy = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    const doesExist = sessionStorage.getItem(key)
    if (!doesExist) {
      setTimeout(() => {
        setIsOpen(true)
      }, 1000)
    }
  }, [])

  const handleAccept = () => {
    if (checked) sessionStorage.setItem(key, "true")
    setIsOpen(false)
  }

  const handleChange = (event) => {
    setChecked(event.target.checked)
  }

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ ".MuiPaper-root": { width: "100%" } }}
    >
      <DialogTitle
        id="alert-dialog-title"
        className="text-center !text-2xl border-b !font-bold !text-black !opacity-95"
      >
        Protecting Your Privacy Is Important To Us
      </DialogTitle>
      <DialogContent className="!py-5">
        <DialogContentText id="alert-dialog-description">
          <span>
            This Privacy Notice applies to our website -{" "}
            <a
              href="https://www.fcmb.com"
              target="_blank"
              rel="noreferrer"
              className="text-primary hover:underline"
            >
              www.fcmb.com
            </a>{" "}
            and its associated subdomains (collectively, our “Service”). By accessing or using our
            Service, you consent and signify that you have read, understood, and agreed to our data
            collection practices described in our{" "}
            <a
              href="https://www.fcmb.com"
              target="_blank"
              rel="noreferrer"
              className="text-primary hover:underline"
            >
              Privacy Policy
            </a>
            .
          </span>
          <span className="block mt-4">
            By clicking "OK" you agree FCMB can collect data to be used for the operation of the
            website, maintain quality of service and provide general statistics regarding the
            website.
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="border-t w-full flex !justify-between !p-3">
        <FormControlLabel
          className="!text-gray-600"
          control={
            <Checkbox
              disableRipple
              color="primary"
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
            />
          }
          label="Don't show again"
        />
        <AppButton gradient onClick={handleAccept} className="min-w-[120px] md:min-w-[150px]">
          OK
        </AppButton>
      </DialogActions>
    </Dialog>
  )
}

export default PrivacyPolicy
